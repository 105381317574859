import { format, formatDistance, formatRelative, isAfter, subDays } from "date-fns";

const startDate = new Date("2024-12-02T12:00:00-04:00");
const endDate = (formatString: string = "MMMM d") =>
	format(new Date("2024-12-02T12:00:00-04:00"), formatString);

const percentOff: string = "27%";
const upsellPercentOff: string = "30%";
const salesPrices: { [key: string]: number } = {
	yearly: 144,
	lifetime: 699,
};

const saleVariant: string = isAfter(new Date(), new Date("2024-12-01T12:00:00-04:00"))
	? "cyber monday"
	: "black friday";
const saleLabel: string = `${saleVariant} sale`;
const salesId: string = "bfcm";
const salesYear: string = new Date().getFullYear().toString();

export {
	startDate,
	endDate,
	percentOff,
	upsellPercentOff,
	saleVariant,
	saleLabel,
	salesId,
	salesYear,
	salesPrices,
};
