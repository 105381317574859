"use client";

// from https://stackoverflow.com/a/12709880
declare global {
	interface Window {
		clarity: (action: string, userId: string) => void;
	}
}

import { useEffect } from "react";

import { User } from "@/types";

export function useClarity(user: User) {
	useEffect(() => {
		if (user && "clarity" in window) {
			window?.clarity("identify", user.id);
		}
	}, [user]);
}
