"use client";

import { AlertCircle, CheckCircle2, Info, XCircle } from "lucide-react";

import {
	Toast,
	ToastClose,
	ToastDescription,
	ToastProvider,
	ToastTitle,
	ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";

const icon = {
	default: <Info className="text-black" />,
	success: <CheckCircle2 color="white" className="fill-current" />,
	warning: <AlertCircle color="white" className="fill-current" />,
	error: <XCircle color="white" className="fill-current" />,
};

export function Toaster() {
	const { toasts } = useToast();

	return (
		<ToastProvider>
			{toasts.map(function ({ id, title, description, action, ...props }) {
				return (
					<Toast key={id} {...props}>
						<div className="grid gap-1">
							{title && (
								<ToastTitle>
									{icon[props.variant || "default"]}
									{title}
								</ToastTitle>
							)}
							{description && <ToastDescription>{description}</ToastDescription>}
						</div>
						{action}
						<ToastClose />
					</Toast>
				);
			})}
			<ToastViewport />
		</ToastProvider>
	);
}
